@import "../../sass/variables";

.input-with-label-container {
  margin-bottom: 1.6rem;
  textarea {
    padding: 0.6rem 1.6rem !important;
  }
  .input {
    border: none;
    resize: none;
    width: 100%;
    border-radius: 6px;
    height: 100%;
    font-family: inherit;
    padding: 0 1.6rem;
    background-color: #f5f5f5;

    text-align: left;
    font: $regular-weight 1.4rem/2.1rem $font-name;
    letter-spacing: 0px;
    color: #36373a;

    // opacity: 0.3;

    &:focus {
      outline: none;
    }

    &-label {
      font: $regular-weight 1.4rem/2.1rem $font-name;
      letter-spacing: 0px;
      opacity: 1;
      margin-bottom: 4px;

      .required {
        font: $regular-weight 1.4rem/2.1rem $font-name;
        letter-spacing: 0px;
        color: #ff2700;
      }
    }

    &-container {
      height: 4rem;
    }
  }

  .error-text {
    color:$color-red;
    font-size: 1rem;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 3px;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    display: block;
  }
}
